var user = localStorage.getItem("user");
user = JSON.parse(user);
var arr = [
  {
    title: 'الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'التحكم في الطلاب',
    icon: 'UsersIcon',
    g: 'students',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'استعراض الطلاب',
        route: 'students/view',
        g: 'students-view'
      },
      {
        title: 'إضافة طالب',
        route: 'students/add-or-edit',
        g: 'students-add'
      },
      {
        title: 'استيراد الطلاب',
        route: 'students/import',
        g: 'students-add'
      },
      {
        title: 'انشاء اولياء الامور (تلقائياً)',
        route: 'students/auto-parents',
        g: 'students-add'
      },
    ],
  },
  {
    title: 'أولياء الأمور',
    icon: 'UsersIcon',
    g: 'parents',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'إضافة ولي أمر',
        route: 'parents/add',
        g: 'parents-add'
      },
      {
        title: 'استعراض أولياء الأمور',
        route: 'parents/view', g: 'parents-view'
      },
      {
        title: 'اجهزة اولياء الامور',
        route: 'parents/devices', g: 'parents-edit'
      },
    ],
  },
  {
    title: 'خطوط السير والباصات',
    icon: 'TruckIcon',
    g: 'groups',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'إضافة خط سير',
        route: 'groups/add-or-edit',
        g: 'groups-add'
      },
      {
        title: 'استعراض الخطوط',
        route: 'groups/view',
        g: 'groups-view'
      },
      {
        title: 'إضافة باص',
        route: 'busses/add',
        g: 'busses-add'
      },
      {
        title: 'استعراض الباصات',
        route: 'busses/view',
        g: 'busses-view'
      },
    ],
  },
  {
    title: 'مشرفين الموقع/الباصات',
    icon: 'ShieldIcon',
    g: 'admins',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'إضافة مشرف',
        route: 'admins/add',
        g: 'admins-add'
      },
      {
        title: 'استعراض المشرفين',
        route: 'admins/view',
        g: 'admins-view'
      },
    ],
  },
  {
    title: 'سجل الرحلات',
    route: 'archive',
    g: 'archive',
    icon: 'ClockIcon',
  },
  {
    title: 'الاشعارات السابقة',
    route: 'notifications',
    g: 'notifications',
    icon: 'BellIcon',
  },
  {
    title: 'تنبيهات مشرفين الباصات',
    route: 'alerts',
    g: 'alerts',
    icon: 'AlertTriangleIcon',
  },
  {
    title: 'اعدادات النظام',
    icon: 'SettingsIcon',
    g: 'settings',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'اعدادات عامة',
        route: 'general-settings',
        g: 'settings'
      },
      {
        title: 'الملف الشخصي',
        route: 'profile',
        g: 'settings'
      },
      {
        title: 'اعداد الرسائل والاشعارات',
        route: 'messages-notifications-config',
        g: 'settings'
      },
      {
        title: 'السجلات/Logs للمطورين',
        route: 'logs',
        g: 'settings'
      },
      {
        title: 'الاقسام (المراحل/الفروع)',
        route: 'sections',
        g: 'settings'
      },
    ],
  },
  {
    title: 'الاخبار',
    route: 'news',
    g: 'news',
    icon: 'FileTextIcon',
  },
  {
    title: 'تطبيق الجوال',
    route: 'app',
    g: 'app',
    icon: 'DownloadIcon',
  },
  {
    title: 'خرائط جوجل',
    href: 'https://maps.google.com/',
    icon: 'MapIcon',
  },
], allowed = []

var t = false;

arr.forEach((r) => {
  if (checkPer(r.g)) {
    allowed.push(r)
  } else {
    if (!r.g) {
      allowed.push(r)
    }
  }
})

var i0 = 0
allowed.forEach(function (a) {
  if (a.children) {
    var i = 0
    a.children.forEach(function (per) {
      if (!checkPer(per.g) && per.g) {
        allowed[i0].children[i].disabled = true
      }
      i = i + 1
    })
  }
  i0 = i0 + 1
})

export default allowed
